@import "./../Fonts.css";

:root {
    --scheduleTitleImageDimension: 50pt;
}

.doctorSchedule {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    width: 75vw;
    border-radius: 20pt;
    background-color: #e9e4f8;
    padding: 20pt;
    margin-bottom: 30pt;
}

.noLinkHighlight {
    text-decoration: none;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    overflow-wrap: break-word;
}

th {
    padding-left: 5pt;
    padding-bottom: 5pt;
    font-size: 1.35em;
    font-family: 'LatoRegular';
    text-align: left;
    color: rgb(63, 54, 87);
}

td {
    font-size: 1.1em;
    text-align: left;
    padding: 5pt;
    padding-right: 2vw;
    font-family: 'LatoRegular';
}

tr:nth-child(even) {
    background-color: #9286af;
    color: white;
}

.scheduleTitle {
    display: flex;
    align-items: center; /* Vertical alignment */
}

.scheduleTitleImgWrapper {
    height: var(--scheduleTitleImageDimension);
    width: var(--scheduleTitleImageDimension);
    border-radius: calc(var(--scheduleTitleImageDimension) / 2);
    overflow: hidden;
    flex-shrink: 0;
    flex-grow: 0;
    display: inline-flex;
}

.scheduleTitleImgWrapper img {
    height: var(--scheduleTitleImageDimension);
    width: var(--scheduleTitleImageDimension);
    transition: ease-in-out;
    transition-duration: 300ms;
    flex-shrink: 0;
    flex-grow: 0;
}

.scheduleTitle span {
    display: block;
    text-align: left;
    font-size: 1.75em;
    font-family: 'LatoRegular';
    margin-left: 10pt;
    color: rgb(63, 54, 87);
}

.scheduleTitle span:last-of-type {
    font-size: 1.25em;
    color: rgb(111, 102, 134);
}

.contactInfo {
    text-align: left;
    font-size: 1.15em;
    padding-bottom: 20pt;
}

.scheduleSubtitle {
    margin-top: 40pt;
    margin-bottom: 15pt;
    text-align: left;
    font-size: 1.4em;
    font-family: 'LatoRegular';
    text-decoration: overline;
    color: rgb(67, 54, 90);
}

@media all and (max-width: 1024px) {
    .doctorSchedule {
        padding: 15pt;
        width: calc(95vw - 40pt);
        max-width: none;
    }

    table {
        padding-left: 0;
        font-size: 0.8em;
        font-family: 'LatoLight';
    }

    td, th {
        padding-left: 5pt;
        padding-right: 5pt;
    }
}