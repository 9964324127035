@import "./../Fonts.css";

.cover {
    height: 350pt !important;
    color: white;
}

.fullSize {
    width: 100%;
    height: 100%;
}

.overlay {
    position: absolute;
    top: 0px; 
    left: 0px;
}

.behind {
    z-index: -1;
}

.slide {
    background-size: cover;
    background-position: center;
    color: white;
    height: 100%;
}

.slide1 {
    background-image: url('/public/images/carousel/home1.jpg');
}

.slide2 {
    background-image: url('/public/images/carousel/home2.jpg');
}

.slide3 {
    background-image: url('/public/images/carousel/home3.jpg');
}

.slide4 {
    background-image: url('/public/images/carousel/home4.jpg');
}

.fullSizeOpacityBox {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}

.fullWidth {
    width: 100vw;
}

.fullSizeOpacityBoxDarker {
    color: white;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.fullSizeOpacityBoxLight {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
}

.centerFlex {
    display: flex;
    align-items: center;
    justify-content: center;
}

h3 {
    font-family: 'LatoRegular';
    font-size: 4em;
    margin: 5px
}

p {
    font-family: 'LatoLight';
    font-size: 2em;
    font-weight: 500;
}

.scrollableContent {
    top: 50pt;
    z-index: 1;
}

.subtitleBlock {
    color: rgb(59, 57, 70);
    margin: 50pt 0 50pt 0;
    font-size: 3em;
    font-family: 'LatoRegular';
    width: 100%;
}

.opaque {
    background-color: white;
}

.rightAlignedButtonBand {
    width: fit-content;
    margin-left: auto;
    margin-right: 20pt;
}

.inlineBlock {
    display: inline-block;
}

.unselectable {
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.aFormatCancel {
    text-decoration: none;
    color:rgb(53, 53, 99);
    font-family: 'LatoRegular';
}

.quoteBlock {
    position: relative;
    background-color: rgb(204, 188, 219);
    /* background-image: url("../../images/scribble_shape_1.svg");
    background-size: 100% 100%; */
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: calc(5vw + 10vh);
    margin-bottom: 10vh;
    border-radius: 2vw;
    padding: 7vw 6vw 7vw 6vw;
    font-size: 1.75em;
    font-family: 'LatoRegular';
    color: rgb(76, 73, 122);
    box-sizing: border-box;
}

.quoteBlock > img {
    height: 10vw;
    position: absolute;
    top: -100%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 0;
}

.quoteContent {
    position: relative;
    z-index: 1;
}

.quoteHighlight {
    display: inline;
    color: rgb(74, 30, 99);
}

.subtitle {
    margin-left: 20pt;
    margin-right: 20pt;
}

.pageTitle {
    color: rgb(63, 54, 87);
    font-size: 3em;
    font-family: 'LatoBold';
    width: 100vw;
    padding: 30pt 0 30pt 0;
}

@media all and (max-width: 1024px) {
    .subtitle {
        font-size: 1.75em;
    }

    .subtitleBlock {
        font-size: 2em;
        margin: 25pt 0 25pt 0;
    }
    
    .quoteBlock {
        margin-top: calc(5vw + 5vh);
        margin-bottom: 5vh;
        font-size: 1.2em;
    }

    .quoteHighlight {
        font-size: 1.15em;
    }

    .pageTitle {
        padding: 20pt 0 20pt 0;
        font-size: 2em;
    }
}