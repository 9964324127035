.carousel {
    width: 100%;
    height: 100%;
}

.carousel.pointer-event {
    touch-action: pan-y;
}

.carousel-inner {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.carousel-inner:after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    height: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
    display: block
}

.active.carousel-item-right, .carousel-item-next:not(.carousel-item-left) {
    transform: translateX(100%)
}

.active.carousel-item-left, .carousel-item-prev:not(.carousel-item-right) {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right, .carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {
    .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
        transition: none
    }
}

.carousel-control-next, .carousel-control-prev {
    display: none;
}

.carousel-indicators {
    display: none;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: black;
    text-align: center
}