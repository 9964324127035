.imageCaption {
    font-size: 0.95em;
    color: rgb(65, 57, 65);
    text-align: center;
    font-style: italic;
    margin-bottom: 30pt;
}

.articleImage {
    margin-top: 30pt;
    margin-bottom: 30pt;
    max-width: 40vw;
}

.smaller.articleImage {
    max-width: 30vw;
}

@media all and (max-width: 1024px) {
    .articleImage {
        max-width: 80vw;
    }
    
    .smaller.articleImage {
        max-width: 80vw;
    }
}