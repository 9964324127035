@font-face {
    font-family: 'LatoRegular';
    src: url(../fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'LatoItalic';
    src: url(../fonts/Lato-Italic.ttf) format('truetype');
}

@font-face {
    font-family: 'LatoBlack';
    src: url(../fonts/Lato-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'LatoBold';
    src: url(../fonts/Lato-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'LatoSemiBold';
    src: url(../fonts/Lato-Semibold.ttf) format('truetype');
}

@font-face {
    font-family: 'LatoLight';
    src: url(../fonts/Lato-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'LatoHairline';
    src: url(../fonts/Lato-Hairline.ttf) format('truetype');
}

@font-face {
    font-family: 'LibreBaskervilleBold';
    src: url(../fonts/LibreBaskerville-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'LibreBaskervilleRegular';
    src: url(../fonts/LibreBaskerville-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'LibreBaskervilleItalic';
    src: url(../fonts/LibreBaskerville-Italic.ttf) format('truetype');
}