.Combobox {
    position: relative;
}

.ComboBox-SelectedElement {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.ComboBox-Popover {
    position: absolute;
    top: calc(100%);
    right: 0pt;
    background-color: rgb(42, 33, 54);
    border-radius: 10pt;
    margin: 2pt 2pt 2pt 2pt;
    padding: 10pt;
    font-size: 15pt;
}

.ComboBox-Popover-Element {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    padding-top: 4pt;
    padding-bottom: 4pt;
    transition: 200ms;
}

.ComboBox-Popover-Element:hover {
    transform: scale(1.2);
}