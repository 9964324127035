@import "./Fonts.css";

body {
  margin: 0;
}

:root {
  --navBarHeight: 50pt;
}

.App {
  text-align: center;
  font-family: 'LatoLight';
}

.App-logo {
  float: left;
  display: flex;
  height: 40pt;
  margin: 6px;
}

.App-header {
  background-color: rgb(42, 33, 54);
  color: white;
  margin: 0px;
  align-items: center;
  height: var(--navBarHeight);
  width: 100vw;
  position: fixed;
  z-index: 420;
}

.App-link {
  color: #61dafb;
}

.App-title {
  float: left;
  font-size: 18pt;
  margin: 14pt 6pt 14pt 6pt;
  font-family: 'LatoSemiBold';
}

.App-title {
  text-decoration: none !important;
  color: white;
}

.App-NavButton {
  float: right;
  display: flex;
  font-family: 'LatoRegular';
  color: white;
  transition: ease-in-out 300ms;
  border-radius: 10pt;
  margin: 7pt 2pt 2pt 7pt;
  padding: 10pt;
  font-size: 15pt;
  cursor: pointer;
}

.App-NavButton:hover {
  background-color: rgb(75, 71, 124);
  color: white;
}

.App-NavButton a {
  text-decoration: none !important;
  color: inherit;
}

.App-MenuButton {
  float: right;
  display: none;
  visibility: hidden;
  transition: ease-in-out 300ms;
  border-radius: 10pt;
  margin: 5pt;
  padding: 5pt;
  cursor: pointer;
}

.App-MenuButton:hover {
  background-color: rgb(75, 71, 124);
  color: white;
}

.App-MenuButton > svg {
  height: 30pt;
}

.App-MenuButton.open > svg {
  #open_menu {
    visibility: hidden;
  }

  #exit_menu {
    visibility: visible;
  }
}

@keyframes slideIn {
  from { margin-left: 500pt; opacity:0 }
  to { margin-left: 0pt; opacity:1 } 
}

.App-MenuItem {
  font-family: 'LatoRegular';
  color: black;
  transition: ease-in-out 300ms;
  border-radius: 10pt;
  margin: 7pt 2pt 2pt 7pt;
  padding: 10pt;
  font-size: 20pt;
  cursor: pointer;
  opacity:0;
  animation: slideIn 0.3s;
  animation-fill-mode: forwards;
}

.delay-1 {
  animation-delay: 20ms;
}

.delay-2 {
  animation-delay: 40ms;
}

.delay-3 {
  animation-delay: 60ms;
}

.delay-4 {
  animation-delay: 80ms;
}

.delay-5 {
  animation-delay: 100ms;
}

.delay-6 {
  animation-delay: 120ms;
}

.App-MenuItem a {
  text-decoration: none !important;
  color: inherit;
}

.App-Content {
  position: absolute;
  top: 50pt;
}

.App-NavButton > a {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.App-LangSelect {
  float: right;
  display: flex;
  font-family: 'LatoRegular';
  color: transparent;
  transition: ease-in-out 300ms;
  border-radius: 10pt;
  margin: 7pt 2pt 2pt 7pt;
  padding: 10pt;
  font-size: 15pt;
  cursor: pointer;
}

@keyframes navMenuFadeInAnimation {
  from { background-color: transparent; }
  to { background-color: white; }
}

.App-NavMenu {
  visibility: hidden;
}
 
.langIcon {
  height: 20pt;
}

@media all and (max-width: 1024px) {
  .App-NavMenu {
    visibility: visible;

    position: fixed;
    background: white;
    z-index: 420;

    animation: navMenuFadeInAnimation 500ms;
    animation-fill-mode: forwards;
  }

  .App-MenuButton {
    display: flex;
    visibility: visible;
  }

  .App-NavButton {
    visibility: hidden;
    display: none;
  }

  .App-MenuButton:hover {
    background-color: transparent;
    color: white;
  }
}