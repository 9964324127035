@import "./../Fonts.css";

.teamMemberTitleDisplay {
    --teamImageDimension: min(30vw, 200px);
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.teamMemberTitleDisplayImgWrapper {
    height: var(--teamImageDimension);
    width: var(--teamImageDimension);
    border-radius: calc(var(--teamImageDimension) / 2);
    overflow: hidden;
    float: left;
    flex-shrink: 0;
    flex-grow: 0;
}

.teamMemberTitleDisplayImg {
    height: var(--teamImageDimension);
    width: var(--teamImageDimension);
    transition: ease-in-out;
    transition-duration: 300ms;
}

.teamMemberTitleDisplay:hover .teamMemberTitleDisplayImg {
    transform: scale(1.1);
}

.teamMemberTitleDisplayName {
    font-size: 2.5em;
    font-family: 'LatoRegular';
}

.teamMemberTitleDisplayDescription {
    font-size: 1.45em;
}

.teamMemberTitleDisplayInfo {
    float: left;
    margin-left: 10px;
    text-align: left;
}
