@import "./../Fonts.css";

:root {
    --highlightImageWidth: 20vw;
}

.highlight {
    display: grid;
    grid-template-columns: var(--highlightImageWidth) 30pt auto;
    align-items: center;
    align-self: center;
    cursor: default;
    margin: 10pt 10% 10pt 10%;
    transition: ease 500ms;
    padding: 20pt;
    color: black;
}

.highlight.right {
    grid-template-columns: auto 30pt var(--highlightImageWidth);
}

.highlightImg {
    float: left;
    grid-column: 1;
    transition: ease-in-out 300ms;
    width: var(--highlightImageWidth);
}

.highlight.right > .highlightImg {
    grid-column: 3;
    grid-row: 1;
}

.highlightInfo {
    grid-column: 3;
    text-align: left;
    float: left;
}

.highlight.right > .highlightInfo {
    grid-column: 1;
    text-align: right;
}

.highlightTitle {
    font-family: 'LatoRegular';
    font-size: 2em;
    transition: ease-in-out 300ms;
    margin-bottom: 20pt;
}

.highlightDescription {
    font-family: Open Sans, Arial, Helvetica, sans-serif;
    font-size: 1.375em;
}

.highlight:hover {
    background: linear-gradient(to right, white, rgb(244, 237, 252), white);
    margin: 10pt 8% 10pt 12%;
}

.highlightDescription h3 {
    font-size: 1em;
    color: rgb(90, 33, 90);
    font-family: 'LatoSemiBold';
}

.highlightImgMini {
    display: none;
}

@media all and (max-width: 1024px) {
    .highlight {
        margin: 20pt 2.5% 20pt 2.5%;
        display: flex;
        align-items: center;
        position: relative;
        padding: 2.5vw;
    }

    .highlight:hover {
        margin: 20pt 0.5% 20pt 4.5%;
    }

    .highlightImg {
        display: none;
    }

    .highlightTitleWrapper {
        display: grid;
        grid-template-columns: max(10vw, 50pt) 10pt auto;
        margin-bottom: 10pt;
        align-items: center;
        align-self: center;
    }

    .highlight.right .highlightTitleWrapper {
        grid-template-columns: auto 10pt max(10vw, 50pt);
    }

    .highlightImgMini {
        display: block;
        grid-column: 1;
        grid-row: 1;
        width: 100%;
    }

    .highlight.right .highlightImgMini {
        grid-column: 3;
    }

    .highlightTitle {
        grid-row: 1;
        grid-column: 3;
        font-size: 1.5em;
        margin-bottom: auto;
        margin-top: auto;
    }

    .highlight.right .highlightTitle {
        grid-column: 1;
    }

    .highlightDescription {
        font-size: 1.2em;
        font-family: 'LatoLight';
    }
}