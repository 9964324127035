@import "./../Fonts.css";

.teamMember {
    --teamImageDimension: min(40vw, 225px);
    width: fit-content;
    float: left;
    margin: 40pt;
    cursor: pointer;
}

.teamMemberImgWrapper {
    height: var(--teamImageDimension);
    width: var(--teamImageDimension);
    border-radius: calc(var(--teamImageDimension) / 2);
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    flex-shrink: 0;
    flex-grow: 0;
    margin-bottom: 5pt;
}

.teamMemberImg {
    height: var(--teamImageDimension);
    width: var(--teamImageDimension);
    transition: ease-in-out;
    transition-duration: 300ms;
    flex-shrink: 0;
    flex-grow: 0;
}

.teamMember:hover .teamMemberImg {
    transform: scale(1.1);
}

.teamMemberName {
    font-size: 2.5em;
    font-family: 'LatoRegular';
}

.teamMemberDescription {
    font-size: 1.45em;
}

@media all and (max-width: 1024px) {
    .teamMemberName {
        font-size: 2em;
        font-family: 'LatoRegular';
    }

    .teamMemberDescription {
        font-size: 1.25em;
    }

    .teamMember {
        margin: 20pt;
    }
}