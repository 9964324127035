@import "./../Fonts.css";

:root {
    --articlePadding: 20vw;
}

@media all and (max-width: 1024px) {
    :root {
        --articlePadding: 5vw;
    }
}

.articleCover {
    width: 100vw;
    height: 200pt;
    background-color: #666666;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.articleTitle {
    font-family: "LatoBold";
    font-size: 2.25em;
    color: #ffffff;
    width: fit-content;
    padding-left: 3pt;
    padding-right: 3pt;
}

.articlePublicationDate {
    font-family: "LatoLight";
    font-size: 1.25em;
    color: rgb(224, 224, 224);
    padding-left: 3pt;
    padding-right: 3pt;
    width: fit-content;
}

.articleContent {
    text-align: left;
    padding-left: var(--articlePadding);
    padding-right: var(--articlePadding);
    padding-top: 20pt;
    padding-bottom: 20pt;
    font-family: "LatoRegular";
    line-height: 1.6;
    font-size: 1.175em;
}

.fullSizeOpacityBox {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.articleCover hr {
    width: 7vw;
    height: 1.5pt;
    background-color: rgb(62, 49, 179);
    margin-top: 15pt;
    margin-bottom: 15pt;
}

.articleGridContainer {
    width: 100vw;
    display: grid;
    grid-template-columns: auto 92vw auto;
    padding-top: 4vw;
}

.articleGrid {
    grid-column: 2;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 10vw);
    grid-gap: 1.5rem; 
}

.articleGridImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block; 
}

.articleGridItem1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    position: relative;
}

.articleGridItemCover {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.356);
    transition: ease-in-out;
    transition-duration: 200ms;
}

.articleGridItemCover:hover {
    background-color: rgba(0, 0, 0, 0.185);
}

.articleGridItemCaption {
    text-align: left;
    bottom: 0;
    box-sizing: border-box;
    padding: 20px;
    pointer-events: all;
    position: absolute;
    width: 100%;
    color: white;
}

.articleGridItemTitle {
    font-family: 'LatoBold';
    font-size: 1.5em;
}

.articleGridItemSubtitle {
    font-family: 'LatoLight';
    font-size: 1em;
}

@media all and (max-width: 1024px) {
    .articleGrid {
        display: flex;
    }

    .articleGridItem {
        height: 30vh;
        width: 100%;
    }
}