.teamWrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Wrap child elements to next line if they fill up horizontally */
    margin-left: 5vw;
    margin-right: 5vw;
}

.teamMemberDetailsDescription{
    text-align: left;
    font-size: 1.25em;
    margin-top: 20pt;
    margin-bottom: 20pt;
}

.teamMemberDetailsWrapper {
    width: 60vw;
    margin: auto;
}

.topMostSlider {
    animation: animateright 0.3s;
    min-height: calc(100vh - 50pt);
    width: 100vw;
}

.iconWithTextButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20pt;
    margin-bottom: 0pt;
}

.iconImg {
    width: 27pt;
    height: 27pt;
}

.iconText {
    font-size: 2em;
    font-family: 'LatoSemiBold';
    padding-left: 20px;
    color: rgb(53, 53, 99);
}

.teamWrapper a {
    text-decoration: none;
    color: black;
}

.topMostSlider a {
    text-decoration: none;
}

@keyframes animateright{
    from {
        left: 50vw; 
        opacity:0
    } to { 
        left:0px; 
        opacity:1
    }
}

@media all and (max-width: 1024px) {
    .teamWrapper {
        margin-left: 0;
        margin-right: 0;
    }

    .teamMemberDetailsWrapper {
        width: 90vw;
    }

    .iconText {
        font-size: 1.5em;
        padding-left: 10px;
    }

    .iconImg {
        width: 17pt;
        height: 17pt;
    }

    .iconWithTextButton {
        margin-top: 10pt;
        margin-bottom: 20pt;
    }
}
