.languageChoice {
    padding: 15pt;
    font-size: 2em;
    float: none;
    display: block;
    transition: ease-in-out;
    transition-duration: 300ms;
}

.languageChoice:hover {
    background: rgba(244, 237, 252, 0.35);
}

.languageIcon {
    display: inline;
    vertical-align: middle;
    margin-right: 2.5pt;
}
  
.languageIcon > .langIcon {
    height: 25pt;
}