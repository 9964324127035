:root {
    --leftColumnWidth: 250pt;
    --selectorColor: rgb(85, 82, 117);
    --rightColumnPadding: 5vw;
}

@media all and (max-width: 1024px) {
    :root {
        --leftColumnWidth: 90vw;
        --selectorColor: rgb(85, 82, 117);
        --rightColumnPadding: 10vw;
    }
}

.leftColumn {
    position: fixed;
    top: var(--navBarHeight);
    width: var(--leftColumnWidth);
    height: calc(100vh - var(--navBarHeight));
    background-color: rgb(244, 237, 252);
    overflow-x: hidden;
    overflow-y: auto;
}

.noPadding {
    padding: 0 0 0 0 !important;   
}

.rightColumn {
    position: fixed;
    left: var(--leftColumnWidth);
    top: var(--navBarHeight);
    width: calc(100vw - var(--leftColumnWidth));
    text-align: left;
    font-size: 1.1em;
    padding: var(--rightColumnPadding);
    padding-top: calc(var(--rightColumnPadding) / 2);
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - var(--navBarHeight));
    line-height: 1.6;
}

.selector {
    display: block;
    text-decoration: none;
    text-align: left;
    font-size: 1.125em;
    padding: 7.5pt 5pt 7.5pt 10pt;
    margin-top: 5pt;
    margin-bottom: 5pt;
    margin-right: 5pt;
    border-radius: 0pt 10pt 10pt 0pt;
    cursor: pointer;
    transition-duration: 200ms;
    color: rgb(34, 30, 54);
    font-family: 'LatoRegular';
    user-select: none;
}

.selector.selected {
    color: white;
    background-color: var(--selectorColor);
    font-family: 'LatoSemiBold';
}

.selector:hover {
    background-color: rgb(209, 201, 218);
    padding: 7.5pt 0pt 7.5pt 15pt;
}

.selector.selected:hover {
    background-color: var(--selectorColor);
    padding: 7.5pt 5pt 7.5pt 10pt;
}

.rightColumn h1 {
    font-size: 2em;
    font-family: 'LatoSemiBold';
    font-weight: 100;
    color: rgb(59, 47, 114);
    margin-bottom: 30pt;
    margin-top: 0pt;
}

.rightColumn h2 {
    font-size: 1.35em;
    font-family: 'LatoSemiBold';
    font-weight: 100;
    margin-top: 30pt;
    color: rgb(103, 92, 151);
}

.rightColumn h3 {
    font-size: 1.15em;
    font-family: 'LatoSemiBold';
    font-weight: 100;
    margin-left: 0;
    margin-top: 15pt;
    color: rgb(66, 66, 66);
}

.rightColumn em {
    font-size: 1.15em;
    font-family: 'LatoSemiBold';
    margin-left: 0;
    margin-top: 15pt;
    color: rgb(106, 107, 160);
    font-style: normal;
}

.rightColumn ul ul em {
    font-size: 1.15em;
    font-family: 'LatoSemiBold';
    margin-left: 0;
    margin-top: 15pt;
    color: rgb(109, 109, 109);
    font-style: normal;
}

.leftColumnMobileNav {
    display: none;
}

@media all and (max-width: 1024px) {
    .leftColumn {
        position: absolute;
        top: 0;
        left: calc(0pt - var(--leftColumnWidth) - 5pt);
        z-index: 69;
        transition: ease-in-out;
        transition-duration: 200ms;
    }
    
    .rightColumn {
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
    }

    .leftColumnMobileNav {
        display: block;
        position: fixed;
        top: calc(var(--navBarHeight) + 5pt);
        left: 0;
        width: 30pt;
        height: 30pt;
        background-color: rgb(67, 45, 109);
        border-radius: 0 30pt 30pt 0;
        cursor: pointer;
        z-index: 69;
    }
    
    .leftColumnMobileNavImg {
        width: 25pt;
        margin-top: 2.5pt;
    }
    
    .leftColumnToggle {
        left: 0;
    }
}

.accordionButton {
    background-color: rgb(211, 191, 223);
    text-align: left;
    font-size: 1.25em;
    padding: 7.5pt 5pt 7.5pt 10pt;
    cursor: pointer;
    transition-duration: 200ms;
    color: rgb(34, 30, 54);
    font-family: 'LatoSemiBold';
    user-select: none;
    margin-top: 2pt;
}

.accordionButton svg {
    width: 20pt;
    fill: rgb(65, 1, 65);
    float: right;
    transform: rotate(90deg);
    transition-duration: 200ms;
}

.accordionButton,.collapsed svg {
    transform: rotate(0deg);
}

.accordionButton span {
    display: inline-block;
    width: calc(100% - 20pt);
}

.accordionContainer {
    max-height: 100vh;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
}

.collapsed .accordionContainer {
    max-height: 0;
}

.wikiHomeFullSize {
    height: calc(100vh - var(--navBarHeight));
    text-align: center;
}

@media all and (max-width: 1024px) {
    :root {
        --wikiHomeHMargin: 2vw;
    }

    .wikiHomeSize img {
        width: 50vw;
        margin-bottom: 2.5vh;
    }

    .wikiHomeFullSize h1 {
        margin-right: var(--wikiHomeHMargin);
        margin-left: var(--wikiHomeHMargin);
        font-size: 1.6em;
    }

    .wikiHomeFullSize h2 {
        margin-right: var(--wikiHomeHMargin);
        margin-left: var(--wikiHomeHMargin);
        font-size: 1em;
    }
}